<template>
  <div>
    <panel-alert hideBottomBar title="调参记录" @onCloseClick="onCloseClick">
      <template v-slot:content>
        <div class="parameter-change-alert-content">
          <div class="parameter-change-alert-content-item" :style="{
            width: item.isMaskItem ? `${100}%` : '50%'
          }" v-for="(item, index) of processedParaArray" :key="index">
            <p class="parameter-change-alert-content-item-title"
              :class="{ 'parameter-change-alert-content-item-title-is-mask-item': item.isMaskItem }">
              {{ item.title }}：
            </p>
            <p class="parameter-change-alert-content-item-old-value"
              :class="{ 'parameter-change-alert-content-item-old-value-is-mask-item': item.isMaskItem }">
              {{ item.oldValue }}
            </p>
            <div class="parameter-change-alert-content-item-right">
              <p class="parameter-change-alert-content-item-right-arrow">→</p>
              <p class="parameter-change-alert-content-item-right-new-value" :class="{
                'parameter-change-alert-content-item-right-new-value-isChange':
                  item.newValue != item.oldValue,
              }
                ">
                {{ item.newValue }}
              </p>
            </div>
          </div>
        </div>
      </template>
    </panel-alert>
  </div>
</template>

<script>
import PanelAlert from "@c/common/alert/panel-alert/panel-alert.vue";
export default {
  components: {
    PanelAlert,
  },

  model: {
    prop: "isShow",
    event: "onIsShowChanged"
  },

  props: {
    isShow: Boolean,
    paraArray: Array,
  },

  computed: {
    currentMode() {
      return this.modeArray[this.currentModeIndex];
    },

    processedParaArray() {
      return this.paraArray.map((item) => {
        return {
          title: item.title,
          oldValue: item.oldValue,
          newValue: item.newValue,
          isMaskItem: item.title == '面罩类型'
        }
      })
    }
  },

  methods: {
    onCloseClick() {
      this.$emit("onIsShowChanged", false);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@c/common/alert/panel-alert/panel-alert-para-adjust/para-adjust-alert-item.scss";

.parameter-change-alert {
  &-msg {
    color: black;
    font-size: 14px;
    width: 636px;
    height: 289px;
    box-sizing: border-box;
    padding-top: 15px;
    text-align: center;
  }

  &-content {
    width: 636px;
    flex-wrap: wrap;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-item {
      font-size: 14px;
      display: flex;
      height: 20px;
      margin-top: 15px;

      &-title {
        width: 35%;
        text-align: end;
        color: black;

        &-is-mask-item {
          width: 108px;
        }
      }

      &-old-value {
        width: 25%;
        color: black;

        &-is-mask-item {
          width: auto;
        }
      }

      &-right {
        margin-left: 8px;
        display: flex;

        &-arrow {
          color: #c1c1c1;
        }

        &-new-value {
          color: #666666;
          margin-left: 10px;

          &-isChange {
            color: #2e6be6;
          }
        }
      }
    }
  }
}
</style>